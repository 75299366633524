/*eslint-disable*/
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import PrivateRouteFactory from 'common/components/Routes/PrivateRoute';
import Home from 'app/Pages/Home';
import URLConfig from 'app/Pages/URLConfig';

import routerMap from './RouterMap';
import './styles.scss';

const PrivateRoute = PrivateRouteFactory(routerMap.home.path);

const Main = () => {
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route exact path={routerMap.home.path} component={Home} />
          <Route exact path={routerMap.config.path} component={URLConfig}
        />
        </Switch>
      </Router>
    </div>
  );
}

export default Main;
