import React from 'react';

import AudioEmbed from 'app/Views/AudioEmbed';
import fromQS from 'common/utils/fromQS';

import './styles.scss';

const Home = () => {
  const resource = fromQS('resource');

  return resource ? (
    <div className="audio-embed">
      <AudioEmbed resource={resource} />
    </div>
  ) : (
    <div>URL needs to be configured</div>
  );
};

export default Home;
