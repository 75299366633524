import React from 'react';
import { string } from 'prop-types';
import Embed from 'react-music-embed';

function AppleMusicEmbed({ resource }) {
  return <Embed url={resource} width="100%" height="100%" />;
}

AppleMusicEmbed.propTypes = {
  resource: string.isRequired,
};

AppleMusicEmbed.defaultProps = {};

export default AppleMusicEmbed;
