/* eslint-disable */

import qs from 'query-string';

const fromQS = (propName, shouldCache) => {
  const extract = (key) => {
    const params = qs.parse(window.location.search);
    return params[key];
  };

  if (shouldCache) {
    return () => extract(propName);
  }

  return extract(propName);
};

export default fromQS;
