import React from 'react';
import { func, string } from 'prop-types';
import { Route, Redirect } from 'react-router-dom';

// ToDO come back to isAuthenticated
function PrivateRouteHoC(signInPath) {
  function PrivateRoute({ component: Component, requiredRole, ...rest }) {
    const isAuthenticated = true;

    const getRoute = (props) => {
      if (!isAuthenticated) {
        return <Redirect to={signInPath} />;
      }

      return <Component {...props} />;
    };

    return <Route {...rest} render={(props) => getRoute(props)} />;
  }

  PrivateRoute.propTypes = {
    component: func.isRequired,
    requiredRole: string,
  };

  PrivateRoute.defaultProps = {
    requiredRole: undefined,
  };

  return PrivateRoute;
}
export default PrivateRouteHoC;
