const routerMap = {
  home: {
    path: '/',
    getPath: () => '/',
  },
  config: {
    path: '/config',
    getPath: () => '/config',
  },
};

export default routerMap;
