import React from 'react';
import { string } from 'prop-types';

import AppleMusicEmbed from 'app/Views/AppleMusicEmbed';
import SoundCloudEmbed from 'app/Views/SoundCloudEmbed';
import SpotifyEmbed from 'app/Views/SpotifyEmbed';

function getDomain(resource) {
  const hostname = new URL(resource)?.hostname || '';
  const sld = hostname.substr(0, hostname.lastIndexOf('.')).split('.').pop();

  return sld?.toUpperCase();
}

function AudioEmbed({ resource }) {
  const domain = getDomain(resource);

  switch (domain) {
    case 'APPLE': {
      return <AppleMusicEmbed resource={resource} />;
    }
    case 'SOUNDCLOUD': {
      return <SoundCloudEmbed resource={resource} />;
    }
    case 'SPOTIFY': {
      return <SpotifyEmbed resource={resource} />;
    }
    default:
      return <div>URL Source not accepted</div>;
  }
}

AudioEmbed.propTypes = {
  resource: string.isRequired,
};

AudioEmbed.defaultProps = {};

export default AudioEmbed;
