/* eslint-disable */

function PostConfigCompleted(payload) {
  if (window.parent) {
    window.parent.postMessage(
      {
        event: 'PLUGIN_CONFIG_COMPLETED',
        payload: payload || {},
      },
      '*',
    );
  }
}

export default PostConfigCompleted;
