import React from 'react';
import { func, string } from 'prop-types';
import { Formik } from 'formik';
import * as yup from 'yup';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';

import Button from 'common/components/Buttons/Button';

const validationSchema = yup.object({
  url: yup.string().url('URL is not valid').required('URL is required'),
});

function URLInput({ notes, onSubmit }) {
  return (
    <div>
      <Formik
        initialValues={{}}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          touched,
          errors,
          values,
        }) => {
          return (
            <Form noValidate>
              <Form.Row>
                <Col sm={12}>
                  <Form.Group>
                    <Form.Label htmlFor="url">URL</Form.Label>
                    <Form.Control
                      name="url"
                      type="text"
                      value={values.url}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={touched.url && errors.url}
                    />
                    {touched.url && errors.url && (
                      <Form.Control.Feedback type="invalid">
                        {errors.url}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                </Col>
              </Form.Row>

              {notes && (
                <div className="mb-4">
                  <i>{notes}</i>
                </div>
              )}

              <div className="d-flex justify-content-end">
                <Button variant="primary" onClick={handleSubmit}>
                  Accept
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}

URLInput.propTypes = {
  notes: string,
  onSubmit: func.isRequired,
};

URLInput.defaultProps = {
  notes: undefined,
};

export default URLInput;
