import React from 'react';
import { string } from 'prop-types';

const SOUND_CLOUD_BASE_URL = 'https://w.soundcloud.com/player';

const DEFAULT_PARAMS = new URLSearchParams({
  auto_play: false,
  hide_related: true,
  show_comments: false,
  show_user: true,
  show_reposts: false,
  visual: true,
}).toString();

function getEmbedUrl(soundCloudResource) {
  const embedUrl = `${SOUND_CLOUD_BASE_URL}/?url=${soundCloudResource}&${DEFAULT_PARAMS}`;

  return embedUrl;
}

function SoundCloudEmbed({ resource }) {
  const embedUrl = getEmbedUrl(resource);

  return (
    <iframe
      src={embedUrl}
      width="100%"
      height="100%"
      title="soundcloud-player"
      frameBorder="0"
    />
  );
}

SoundCloudEmbed.propTypes = {
  resource: string.isRequired,
};

SoundCloudEmbed.defaultProps = {};

export default SoundCloudEmbed;
