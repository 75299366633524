import React from 'react';
import { string } from 'prop-types';
import spotifyUri from 'spotify-uri';

const SPOTIFY_BASE_URL = 'https://open.spotify.com/embed';

function getEmbedUrl(spotifyResource) {
  const type = spotifyResource.type;
  const id = spotifyResource.id;
  const embedUrl = `${SPOTIFY_BASE_URL}/${type}/${id}`;

  return embedUrl;
}

function SpotifyEmbed({ resource }) {
  const spotifyResource = spotifyUri.parse(resource);
  const embedUrl = getEmbedUrl(spotifyResource);

  return (
    <iframe
      src={embedUrl}
      title="spotify-player"
      width="100%"
      height="100%"
      frameBorder="0"
      allowtransparency="true"
      allow="encrypted-media"
    />
  );
}

SpotifyEmbed.propTypes = {
  resource: string.isRequired,
};

SpotifyEmbed.defaultProps = {};

export default SpotifyEmbed;
