import React from 'react';

import URLInput from 'app/Views/URLInput';
import PostConfigCompleted from 'app/Services/Events/PostConfigCompleted';

import './styles.scss';

const URLConfig = () => {
  const handleComplete = ({ url }) => {
    PostConfigCompleted({
      queryParams: { resource: url },
      height: '160px',
      popOutWidth: '300',
      popOutHeight: '115',
    });
  };

  return (
    <div className="audio-url-config p-3">
      <URLInput
        notes="Direct URL should be used. Apple Music, SoundCloud, and Spotify URLs are supported."
        onSubmit={handleComplete}
      />
    </div>
  );
};

export default URLConfig;
